@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
body {
  background-color: #fbfbfb;
}
@media screen and (min-width: 992px) {
  main {
    margin-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 992px) {
  .sidebar {
    width: 100%;
  }
}
.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.color-pcr {
  background-color: #0065A4;
}

/*
Button scrolling up start
 */
#btn-back-to-top {
  position: fixed;
  bottom: 40px;
  right: 10px;
  display: none;
}
/*
Button scrolling up end
 */

.hidden-arrow::after {
  content:none;
  text-decoration: none;
}

.smallTitle {
  font-size: 9px;
  width: auto;
  text-align: center;
}

a.datatable-sorter {
  display: block;
  color: inherit;
  text-decoration: inherit;
}
a.page-link {
  cursor: pointer;
}

thead.no-bold th {
  font-weight: normal;
}

.primary-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
}

.danger-tooltip {
  --bs-tooltip-bg: var(--bs-danger);
}

.success-tooltip {
  --bs-tooltip-bg: var(--bs-success);
}

.info-tooltip {
  --bs-tooltip-bg: var(--bs-info);
}

.warning-tooltip {
  --bs-tooltip-bg: var(--bs-warning);
}

.secondary-tooltip {
  --bs-tooltip-bg: var(--bs-secondary);
}

/* highlightTyped use mark */
.autocomplete-menu mark {
  text-decoration: underline;
  background: none;
  color: currentColor;
  padding: 0;
}

/* Optional nicer scrollbars */
.autocomplete-menu {
  left: 120px;
  top: 40px;
  width: 100%;
  --scroller-color: 0, 0%;
  --scroller-color-lightness: 80%;
  --scroller-bg-lightness: 90%;
  --scroller-hover-factor: 0.8;
  --scroller-thumb: hsl(var(--scroller-color), var(--scroller-color-lightness));
  /* Replicate hover for webkit */
  --scroller-thumb-hover: hsl(var(--scroller-color), calc(var(--scroller-color-lightness) * var(--scroller-hover-factor)));
  --scroller-background: hsl(var(--scroller-color), calc(var(--scroller-bg-lightness)));
  scrollbar-color: var(--scroller-thumb) var(--scroller-background);
  scrollbar-width: thin;
}

.autocomplete-menu::-webkit-scrollbar {
  width: 8px;
}

.autocomplete-menu::-webkit-scrollbar-track {
  background: var(--scroller-background);
}

.autocomplete-menu::-webkit-scrollbar-thumb {
  background: var(--scroller-thumb);
}

.autocomplete-menu::-webkit-scrollbar-thumb:hover {
  background: var(--scroller-thumb-hover);
}


/* Square CSS Loader */
/* HTML: <div class="loader"></div> */
#loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10000;
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background:linear-gradient(90deg,#000 50%,#0000 0) right/200% 100%;
  animation: l21 2s infinite linear;
}
#loader::before {
  content :"Loading...";
  color: #0000;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg,#fff 50%,#000 0);
  -webkit-background-clip:text;
  background-clip:text;
}

@keyframes l21{
  100%{background-position: left}
}

/*
End spinner
 */

/**
 * Event cursors
 */
div.fc-event-title-container {
  cursor: pointer;
}

div.fc-daygrid-event-harness {
  cursor: pointer;
}
td.fc-list-event-title {
  cursor: pointer;
}
/**
 * End event cursors
 */
